@import "scss/framework";

.ServiceStatusBadge {
  border-color: inherit;

  &.new {
    color: $color-error;
  }

  &.progress {
    color: $color-secondary;
  }

  &.finished {
    color: $color-success;
  }

  &.received {
    color: $color-grey;
  }
}

.Icon {
  color: inherit !important;
}
