@import "scss/framework";

.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: 16px;
  }
}

.AvatarInput {
  position: relative;
  width: 168px;
  height: 168px;
}

.Image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.Button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: transparentize($color-primary, 0.2);
  color: #ffffff;
  transition: background 0.2s, opacity 0.2s;

  &:hover {
    background: transparentize($color-primary, 0.1);
  }

  &.imagePresent {
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }
}
