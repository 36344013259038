@import "scss/framework";

.EditServiceActionsForm {

}

.RemoveFieldButton {
  margin-top: 6px;
  text-align: right;
}

.AddFieldButton {
  margin-top: 8px;
  align-items: flex-start;
  justify-content: space-between;
}

.ApiError {
  margin-bottom: 32px;
}

.ActionsSubtitle {
  margin-top: 32px;
  margin-bottom: 24px;
}
