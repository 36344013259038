@import "scss/framework";

.AppNavbar {
  background: #ffffff;
  z-index: 2;
  border-radius: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.Toolbar {
  display: flex;
  justify-content: space-between;
}

.Title {
  display: flex;
  align-items: center;
}

.TitleIcon {
  margin-right: 8px;
}

.TitleName {
  display: none;

  @include size-up(340px) {
    display: inline-block;
  }
}
