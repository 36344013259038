@import "scss/framework";

.App {
  min-height: 100vh;
  background: $color-background;
}

.Snackbar {
  border-radius: 8px;
}
