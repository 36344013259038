@import "scss/framework";

.EditVehiclePartsForm {

}

.RemoveFieldButton {
  margin-top: 6px;
  text-align: right;
}

.AddFieldButton {
  margin-top: 16px;
}

.ApiError {
  margin-bottom: 32px;
}
