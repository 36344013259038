@import "scss/framework";

.ClientsTable {

}

.Cars {
  > * {
    margin: 4px 8px 4px 0;
  }
}
