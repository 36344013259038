@import "scss/framework";

.MainMenu {

}

.Button {
  color: #fff;
  opacity: 0.8;
  transition: opacity 0.2s, background-color 0.2s;
  padding-top: 16px;
  padding-bottom: 16px;

  @include md {
    margin-left: 16px;
    border-radius: 8px 0 0 8px;
  }

  &:hover {
    opacity: 1;
  }

  &.selected {
    background: $color-background;
    color: $color-primary;
    opacity: 1;

    .Icon {
      color: $color-primary;
    }
  }
}

.Icon {
  color: white;
}
