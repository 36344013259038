@import "scss/framework";

.DistinctList {

}

.ListItem {
  + .ListItem {
    margin-top: 16px;
  }
}

.ListValue {
  font-size: 16px;
  font-weight: 500;
}

.ListLabel {

}
