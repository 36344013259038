@import "scss/framework";

.PublicAppBar {
  border-radius: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);

  &.default {
    color: #ffffff;

    * {
      color: inherit;
    }
  }

  &.inverted {
    background-color: #ffffff;
    color: $color-primary;

    * {
      color: inherit;
    }
  }
}

.Toolbar {
  display: flex;
  justify-content: space-between;
}

.Title {
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: opacity .2s;

  &:hover {
    opacity: 0.8;
  }
}

.TitleIcon {
  margin-right: 8px;
}

.TitleName {
  display: none;

  @include size-up(340px) {
    display: inline-block;
  }
}
