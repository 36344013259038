@import "scss/framework";

.ServicesPage {

}

.Search {
  margin-bottom: 36px;
}

.Pagination {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}
