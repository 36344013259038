@import "scss/framework";

.AppWrapper {

}

.Wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 32px;

  @include md {
    width: calc(100% - $sidebar-width);
    margin-left: $sidebar-width;
  }
}

.Main {
  flex-grow: 1;
}
