@import "scss/framework";

.ServicePage {

}

.Main {
  align-items: stretch;

  :global(.MuiCard-root) {
    height: 100%;
  }
}

.PaymentType {
  display: flex;
  align-items: center;

  :global(.MuiSvgIcon-root) {
    margin-top: -1px;
    margin-right: 4px;
  }
}

.AcceptField {
  display: flex;
  align-items: center;

  * {
    color: inherit;
  }

  &.yes {
    color: $color-success;
  }

  &.no {
    color: $color-error;
  }
}

.AcceptIcon {
  margin-top: -1px;
  margin-left: 4px;
}
