@import "scss/framework";

.PageLoader {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 64px;
}

.LinearProgress {
  width: 100%;
}
