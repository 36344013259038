@import "scss/framework";

.Sidebar {
  position: relative;
  z-index: 1;

  :global(.MuiDrawer-paper) {
    width: 100%;
    max-width: $sidebar-width;
    border: none;
    background: $color-primary;
    border-radius: 0;
  }
}

.MainMenu {
  margin-top: 8px;
}
