@import "scss/framework";

.ClientPage {

}

.Grid {
  @include md {
    align-items: flex-end;
  }
}

.CarActionWrapper{
  height: 100%;
}

.CarCard {
  height: 100%;
}
