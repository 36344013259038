@import "scss/framework";

.ClientCard {
  background-color: darken($color-secondary, 5%);
  color: #ffffff;

  * {
    color: inherit;
  }
}
