@import "scss/framework";

.CarCard {
  background: $color-primary;
  color: #ffffff;
  transition: background-color 0.2s;

  &:hover {
    background: mix($color-primary, #ffffff, 90%);
  }
}
