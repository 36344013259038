@import "scss/framework";

.CarsTable {

}

.EmptyRow {
  padding-top: 32px;
  padding-bottom: 16px;
}
