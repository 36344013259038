@import "scss/framework";

.IndexPage {
  min-height: 100vh;
  background: $color-primary;
}

.Intro {
  background: darken($color-primary, 5%);
}

.HeadlineColumn {
  height: 100%;
  padding: 128px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include md {
    max-width: $screen-sm;
    margin-left: auto;
    margin-right: 0;
  }
}

.Logo {
  margin-bottom: 32px;
  font-size: 64px;
  color: $color-secondary;
}

.Headline {
  margin-top: 0;
  margin-bottom: 32px;
  color: #ffffff;
  font-weight: 500;
  line-height: 1;
}

.Emphasis {
  color: $color-secondary;
}

.Slogan {
  margin-bottom: 0;
  color: mix($color-primary, #ffffff, 40%);
}

.ImageColumn {
  padding: 0;
}

.Image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.Services {
  padding: 168px 0;
}

.ServicesHeading {
  padding-top: 32px;
  padding-bottom: 32px;
}

.ServicesTitle {
  color: #ffffff;
  margin-bottom: 16px;
  text-align: center;
}

.ServicesSubtitle {
  font-size: 18px;
  font-weight: 500;
  color: mix($color-primary, #ffffff, 40%);
  text-align: center;
}

.ServicesGrid {
  margin-top: 64px;
  align-items: stretch;

  :global(.MuiCard-root) {
    height: 100%;
    background: darken($color-primary, 5%);
    color: #ffffff;

    * {
      color: inherit;
    }
  }

  @include lg {
    order: -1;
  }
}

.Footer {
  padding: 36px 0;
  background: darken($color-primary, 10%);
  color: mix($color-primary, #ffffff);
}
