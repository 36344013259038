@import "palette";

@mixin mono {
  font-family: 'JetBrains Mono', monospace;
}

$sidebar-width: 240px;

$screen-sm: 600px;
$screen-md: 900px;
$screen-lg: 1200px;
$screen-xl: 1536px;

@mixin size-up($size) {
  @media (min-width: #{$size}) { @content; }
}

@mixin size-down($size) {
  @media (max-width: #{$size - 1px}) { @content; }
}

@mixin sm {
  @media (min-width: #{$screen-sm}) { @content; }
}

@mixin md {
  @media (min-width: #{$screen-md}) { @content; }
}

@mixin lg {
  @media (min-width: #{$screen-lg}) { @content; }
}

@mixin xl {
  @media (min-width: #{$screen-xl}) { @content; }
}
