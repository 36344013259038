@import "scss/framework";

.VehiclePage {

}

.Main {
  align-items: stretch;

  :global(.MuiCard-root) {
    height: 100%;
  }
}
