@import "scss/framework";

.QuickActions {
  display: flex;
  flex-wrap: wrap;
  margin: -8px !important;

  > * {
    flex-grow: 1;
    flex-shrink: 0;
    margin: 8px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  :global(.MuiButton-outlined) {
    border-width: 2px;
  }
}
