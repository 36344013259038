@import "scss/framework";

.Footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 64px;
  text-align: center;

  @include sm {
    flex-direction: row;
  }
}

.Copyright {
  font-size: 12px;
  font-weight: 700;
  color: mix($color-primary, $color-background, 40%);
}
