@import "scss/framework";

.UserWidget {
  text-transform: none;
}

.Avatar {
  width: 32px;
  height: 32px;
}

.Name {
  opacity: 1 !important;
  font-weight: 500;
}
