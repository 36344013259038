@import "scss/framework";

.Plate {
  @include mono;
  display: inline-block;
  border: 1px solid $color-grey;
  border-radius: 8px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: -0.5px;
  line-height: 1;

  &.large {
    padding: 5px 8px;
    font-size: 16px;
  }

  &.medium {
    padding: 3px 5px;
    font-size: 14px;
  }

  &.small {
    padding: 2px;
    font-size: 12px;
  }
}
