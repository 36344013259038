@import "scss/framework";

.Page {
  &.narrow {
    width: 100%;
    max-width: $screen-lg;
    margin: 0 auto;
  }
}

.PageHeader {
  margin-bottom: 8px;
}

.PageTitle {
  font-size: 32px;
  font-weight: 700;
}

.Description {
  margin-top: 4px;
}

.Divider {
  margin-bottom: 32px;
}

.Action {
  margin-left: auto;
  padding: 8px 0 8px 12px;
}
