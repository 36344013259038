@import "scss/framework";

.LogInPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  min-height: 100vh;
}

.Card {
  width: 100%;
  max-width: $screen-sm;
}

.Alert {
  width: 100%;
  max-width: $screen-sm;
  margin-bottom: 16px;
}
