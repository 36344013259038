@import "scss/framework";

.MileagesTimeline {

}

.Date {
  font-weight: 500;
}

.Mileage {
  font-weight: 500;
}
